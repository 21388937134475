<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center pt-0">
      <b-link>
        <b-img
          v-if="listing.image !== ''"
          :alt="`${listing.pid}`"
          fluid
          class="card-img-top"
          :src="listing.image + '?v=' + new Date().getTime()"
          @click="getDetailListing([true, listing])"
        />
        <b-img
          v-else
          :alt="`${listing.pid}`"
          fluid
          class="card-img-top"
          :src="require('@/assets/images/zome/listingNotFoundMres.png')"
          @click="getDetailListing([true, listing])"
        />
      </b-link>
    </div>

    <!-- Listing Details -->
    <b-card-body>
      <div class="item-wrapper">
        <div
          class="item-rating"
        >
          <b-button
            v-if="(showRating===true) && ((listing.rating.perc !== null) && (listing.rating.perc !== ''))"
            :variant="(listing.rating.btn_color === 'red' ? 'danger' : listing.rating.btn_color === 'orange' ? 'warning' : 'success')"
            class="btn-sm"
            @click="getModalRatingListing(listing.idListing, null)"
          >
            <feather-icon
              :icon="(listing.rating.btn_color === 'red' ? 'FrownIcon' : listing.rating.btn_color === 'orange' ? 'MehIcon' : 'SmileIcon')"
              class="mr-50"
            />
            <span class="align-middle">{{ listing.rating.perc }}%</span>
          </b-button>
        </div>
        <div>
          <h6
            v-if="listing.preco"
            class="item-price"
          >
            {{ listing.currencyL }}{{ formatPrice(listing.preco) }}{{ listing.currencyR }}
          </h6>
          <h6
            v-else
            class="item-price"
          >
            {{ '\xa0' }}
          </h6>
        </div>
      </div>
      <h6 class="item-name mt-75 mb-75">
        {{ listing.tipologia }}
      </h6>
      <b-card-text class="item-description mt-0 mb-0">
        {{ listing.morada }}
      </b-card-text>
      <b-card-text class="item-description mt-0 mb-0">
        <b
          v-if="((Number(baseDecode(userAuth.id)) === Number(listing.regBy) || Number(baseDecode(userAuth.id)) === Number(listing.idAng))
            || (Number(listing.idHUB) === Number(userAuth.idHUB) && [5, 9, 10, 13].includes(Number(userAuth.id_role))))"
        >
          {{ listing.nickname }}
        </b>
        <b v-else>
          {{ '\xa0' }}
        </b>
      </b-card-text>
      <b-card-text class="item-description mt-1 mb-0">
        <b-media v-if="listing.nameAgent !== null && listing.nameAgent !== ''">
          <template #aside>
            <b-avatar :src="`${listing.imgAgent}`" />
          </template>
          <h6>{{ listing.nameAgent }} - {{ listing.moradaAgent }}</h6>
          <p class="mb-0">
            <template v-if="listing.phoneAgent">
              <b-link :href="`tel:${listing.phoneAgent.trim()}`">
                {{ listing.phoneAgent }}
              </b-link>
            </template>
          </p>
        </b-media>
      </b-card-text>
      <div class="mt-75">
        <b-button
          v-if="listing.versoes[0].desState !== ''"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-secondary"
          class="btn-sm mr-25"
          :style="[{ cursor: 'default' }, getColorByStatus(listing.estado)]"
        >
          {{ listing.versoes[0].desState }} {{ listing.versoes[0].version }}
        </b-button>
        <b-button
          v-if="(typeof listing.versoes[1] !== 'undefined')"
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          variant="outline-secondary"
          class="btn-sm mr-25"
          :style="[{ cursor: 'default' }, getColorByStatus(listing.versoes[1].desState)]"
        >
          {{ listing.versoes[1].desState.charAt(0).toUpperCase() + listing.versoes[1].desState.slice(1) }} {{ listing.versoes[1].version }}
        </b-button>
        <feather-icon
          v-if="appConfig.bUseFavorites === true && listing.iFavorite !== ''"
          icon="StarIcon"
          class="ml-1 text-warning"
          width="20px"
          height="20px"
        />
        <feather-icon
          v-if="appConfig.bUsePartilhas === true && listing.iPartilha !== ''"
          icon="Share2Icon"
          class="ml-1 text-secondary"
          width="20px"
          height="20px"
        />
      </div>
    </b-card-body>

    <slot name="blockActions" />

  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BCardBody, BLink, BImg, BCardText, BButton, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    listing: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => 0,
    },
    appConfig: {
      type: Object,
      default: () => {},
    },
    getModalRatingListing: {
      type: Function,
      required: true,
    },
    userAuth: {
      type: Object,
      default: () => {},
    },
    getDetailListing: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    showRating() {
      try {
        if ((this.listing !== null) && (this.listing !== undefined)) {
          if ('rating' in this.listing
          && ([1, 8, 14].includes(Number(this.userAuth.id_role))
            || (([3, 5, 6, 9, 10, 11, 12, 13].includes(Number(this.userAuth.id_role)) || Number(atob(this.userAuth.id)) === Number(this.listing.idAng) || Number(this.userAuth.extras.idLeader) === Number(this.listing.idAng))
            && Number(this.userAuth.idHUB) === Number(this.listing.idHub)))) {
            return true
          }
        }
      } catch (err) {
        //
      }

      return false
    },
  },
  methods: {
    getColorByStatus(id) {
      if (Number(id) === 1 || (typeof id === 'string' && id.toLowerCase()) === 'ativo') {
        return { 'border-color': '#1BBC9B!important', color: '#1BBC9B' }
      }
      if (Number(id) === 2 || (typeof id === 'string' && id.toLowerCase()) === 'reservado') {
        return { 'border-color': '#9A12B3!important', color: '#9A12B3' }
      }
      if (Number(id) === 3 || (typeof id === 'string' && id.toLowerCase()) === 'vendido') {
        return { 'border-color': '#C49F47!important', color: '#C49F47' }
      }
      if (Number(id) === 4 || (typeof id === 'string' && id.toLowerCase()) === 'cancelado') {
        return { 'border-color': '#D91E18!important', color: '#D91E18' }
      }
      if (Number(id) === 5 || (typeof id === 'string' && id.toLowerCase()) === 'pendente') {
        return { 'border-color': '#F3C200!important', color: '#F3C200' }
      }
      if (Number(id) === 6 || (typeof id === 'string' && id.toLowerCase()) === 'arrendado') {
        return { 'border-color': '#E87E04!important', color: '#E87E04' }
      }
      if (Number(id) === 7 || (typeof id === 'string' && id.toLowerCase()) === 'suspenso') {
        return { 'border-color': '#999999!important', color: '#999999' }
      }
      if (Number(id) === 9 || (typeof id === 'string' && id.toLowerCase()) === 'pré-angariação') {
        return { 'border-color': '#000000!important', color: '#000000' }
      }

      return {}
    },
    baseDecode(val) {
      if (typeof val !== 'undefined') {
        return atob(val)
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
</style>
